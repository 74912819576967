import React from 'react'

const MenuInfoIcon = ({ width = 31, height = 31 }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 563 564"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				className="hover-fill"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M563 282C563 437.744 436.968 564 281.5 564C126.032 564 0 437.744 0 282C0 126.256 126.032 0 281.5 0C436.968 0 563 126.256 563 282ZM319.167 394.667H300.333V225.167C300.333 214.771 291.915 206.333 281.5 206.333H243.833C233.419 206.333 225 214.771 225 225.167C225 235.563 233.419 244 243.833 244H262.667V394.667H243.833C233.419 394.667 225 403.104 225 413.5C225 423.896 233.419 432.333 243.833 432.333H319.167C329.582 432.333 338 423.896 338 413.5C338 403.104 329.582 394.667 319.167 394.667ZM300.333 149.833C300.333 160.235 291.901 168.667 281.5 168.667C271.099 168.667 262.667 160.235 262.667 149.833C262.667 139.432 271.099 131 281.5 131C291.901 131 300.333 139.432 300.333 149.833Z"
				fill="#2C2C2C"
			/>
		</svg>
	)
}

export default MenuInfoIcon
