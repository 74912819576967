import { useBookingsForLayer } from '@/api/hooks/useBookingsForLayer'
import { useLayerView } from '@/api/hooks/useLayerView'
import { useMetadata } from '@/api/hooks/useMetadata'
import { useTree } from '@/api/hooks/useTree'
import { checkHidden } from '@/components/layout/Sidebar/Layers/TreeItem'
import MapStage from '@/components/shared/map/stage/MapStage'
import { useGlobalStore } from '@/stores/globalStore'
import { useMapStore } from '@/stores/mapStore'
import { useProjectStore } from '@/stores/projectStore'
import { RolesEnum, useUserStore } from '@/stores/userStore'
import { formatLocalDateToAPI } from '@/utils/helpers/dates.helpers'
import { addMinutes } from 'date-fns'
import { useEffect, useMemo } from 'react'
import PointsLayer from '../point/PointsLayer'
import PolygonsLayer from '../polygon/PolygonsLayer'
import Tooltip from '../tooltip/Tooltip'
import MapCoverLayer from './MapCoverLayer'
import { useSettingsSelector } from '@/hooks/use-settings-selector'
import { BASE_DOMAIN } from '@/api'

const dispatchLoaded = () => {
	const event = new CustomEvent('map-loaded')
	document.dispatchEvent(event)
}

const MapContainer = () => {
	const setPopupLayer = useGlobalStore((state) => state.setPopupLayer)
	const hideLayers = useSettingsSelector(
		(settings) => settings.hideLayers,
		false,
	)
	const apiUrl = useSettingsSelector(
		(settings) => settings.api.url,
		BASE_DOMAIN,
	)
	const sourceType = useSettingsSelector(
		(settings) => settings.map.images,
		'anonymous',
	)
	const colors = useSettingsSelector((settings) => settings.colors, {})

	const activeLayer = useGlobalStore((state) => state.activeLayer)
	const selection = useGlobalStore((state) => state.selection)
	const nodes = useProjectStore((state) => state.nodes)
	const { layers: treeLayers } = useTree()
	const setMapLayer = useMapStore((state) => state.setMapLayer)
	const role = useUserStore((state) => state.role)

	const node = nodes.find((n) => n.id == activeLayer)
	const hasOwnView = node?.ownView
	const layer = hasOwnView ? node.id : node?.parent || activeLayer

	const { layers } = useTree()
	const { metadata } = useMetadata()
	const { layerView, isSuccess: layerViewLoading } = useLayerView(Number(layer))

	const { data, isSuccess } = useBookingsForLayer(
		Number(layer),
		formatLocalDateToAPI(selection.startDate),
		formatLocalDateToAPI(addMinutes(selection.endDate, -30)),
	)

	useEffect(() => {
		setMapLayer(Number(layer))
	}, [layer, activeLayer])

	useEffect(() => {
		if (isSuccess && layerViewLoading) {
			dispatchLoaded()
		}
	}, [isSuccess, layerViewLoading, activeLayer])

	const nodeFields = useMemo(
		() => treeLayers?.nodes?.find((n) => n.id == layer)?.fields || {},
		[treeLayers?.nodes],
	)
	const isPopup = useMemo(
		() => Boolean(nodeFields['popup'] && nodeFields['popup']?.value === true),
		[nodeFields],
	)

	useEffect(() => {
		if (node && isPopup) {
			setPopupLayer(Number(layer))
		}
	}, [layer, isPopup, node])

	const accessiblePolygons = useMemo(
		() =>
			layerView?.polygons.filter((polygon) => {
				if (!hideLayers) return true
				const curr = layers?.nodes.find((n) => n.id == polygon.id)
				if (!curr) return true

				const isHidden = checkHidden(curr, metadata)

				return role === RolesEnum.Admin ? true : !isHidden
			}),
		[layers?.nodes, layerView?.polygons],
	)

	return (
		<MapStage>
			<MapCoverLayer
				view={layerView?.view}
				url={apiUrl}
				sourceType={sourceType}
			/>
			<PolygonsLayer polygons={accessiblePolygons} />
			<PointsLayer
				colors={colors}
				nodes={metadata?.rawNodes}
				points={layerView?.points}
				options={layerView?.options}
				bookings={data?.bookings}
				url={apiUrl}
				sourceType={sourceType}
			/>
			<Tooltip />
		</MapStage>
	)
}

MapContainer.whyDidYouRender = true

export default MapContainer
