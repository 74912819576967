import { FormLabel } from '@/ui/components/Form/FormLabel'
import Grid from '@/ui/components/Grid'
import React from 'react'
import styled from 'styled-components'
import { Field, useFormikContext } from 'formik'
import RemoveIcon from '@/components/icons/RemoveIcon'
import { DatePickerField } from '@/ui/components/Field/DatePickerField'
import PlusIcon from '@/components/icons/PlusIcon'
import {
  addDays,
  addMinutes,
  getHours,
  getMinutes,
  isAfter,
  isBefore,
  isEqual,
  isSameDay,
  isToday,
  setHours,
  setMinutes
} from 'date-fns'
import useResponsive from '@/hooks/useResponsive'
import media from '@/ui/media'
import { translate } from '@/i18n'
import { RolesEnum, useUserStore } from '@/stores/userStore'
import { useSettingsSelector } from '@/hooks/use-settings-selector'

const BookingInterval = ({ name, interval, index, count, helpers, type }) => {
  const { setFieldValue, values } = useFormikContext<any>()
  const start = interval.start ? addMinutes(interval.start, 30) : null
  const { isDesktop } = useResponsive()
  const role = useUserStore(state => state.role)
  const isAdmin = role === RolesEnum.Admin
  const newWeek = addDays(new Date(), 7)
  const workplace = useSettingsSelector(state => state.workplace)
  const parking = useSettingsSelector(state => state.parking)
  const isWorkPlace = values.seat.type == workplace
  const isParkingPlace = values.seat.type == parking
  const isItSame = interval.end ? isSameDay(interval.start, interval.end) : true
  const minHours = start ? getHours(start) : 0
  const minMinutes = start ? getMinutes(start) : 0
  const minTime = isItSame
    ? setHours(setMinutes(new Date(), minMinutes), minHours)
    : setHours(setMinutes(new Date(), 0), 0)

  const getStartTimes = () => {
    const minDate = new Date()
    const maxDate = newWeek
    const minTime = isToday(interval.start) ? setHours(setMinutes(new Date(), 0), getHours(new Date())) : setHours(setMinutes(new Date(), 0), 0)
    const maxTime = setHours(setMinutes(new Date(), 59), 23)

    const notResticted = {
      minDate: null, maxDate: null, minTime: null, maxTime: null
    }

    const restricted = {
      minDate, maxDate, minTime, maxTime
    }

    if (isAdmin) {
      return notResticted
    }

    if (isWorkPlace || isParkingPlace ) {
      return restricted
    }

    return notResticted
  }

  const getEndTimes = () => {
    const minDateRestricted = new Date()
    const maxDateRestricted = newWeek
    const minTimeRestricted = isToday(interval.end) ? setHours(setMinutes(new Date(), 0), getHours(new Date())) : setHours(setMinutes(new Date(), 0), 0)
    const maxTime = setHours(setMinutes(new Date(), 59), 23)

    const notResticted = {
      minDate: start || null, maxDate: null, minTime, maxTime
    }

    const restricted = {
      minDate: minDateRestricted, maxDate: maxDateRestricted, minTime: minTimeRestricted, maxTime
    }

    if (isAdmin) {
      return notResticted
    }

    if (isWorkPlace || isParkingPlace ) {
      return restricted
    }

    return notResticted
  }

  const startTimes = getStartTimes()
  const endTimes = getEndTimes()


  return (
    <IntervalContainer key={'dates ' + index}>
      <Grid container>
        <Grid item xs={12} md={8}>
          <IntervalGrid>
            <IntervalBox>
              <FormLabel>{translate('start-datetime')}</FormLabel>
              <Field
                name={`${name}[${index}].start`}
                component={DatePickerField}
                $fullWidth
                minDate={startTimes.minDate}
                maxDate={startTimes.maxDate}
                minTime={startTimes.minTime}
                maxTime={startTimes.maxTime}
                onChange={(val) => {
                  const min = setHours(setMinutes(new Date(), 0), getHours(new Date()))
                  const newValue = isBefore(val, min) ? min : val

                  const valueToUse = isAdmin ? val : isWorkPlace ? newValue : val

                  const isIntervalAfter =
                    interval.end && valueToUse ? isAfter(valueToUse, interval.end) : false
                  const equality =
                    interval.end && valueToUse ? isEqual(valueToUse, interval.end) : false

                  if (isIntervalAfter || equality) {
                    setFieldValue(`${name}[${index}].end`, addMinutes(valueToUse, 30))
                  }

                  setFieldValue(`${name}[${index}].start`, valueToUse)
                }}
              />
            </IntervalBox>
            <IntervalBox>
              <FormLabel>{translate('end-datetime')}</FormLabel>
              <Field
                $fullWidth
                name={`${name}[${index}].end`}
                type="text"
                placeholder="Время окончания"
                // selected={start}
                openToDate={start}
                minDate={endTimes.minDate}
                maxDate={endTimes.maxDate}
                minTime={endTimes.minTime}
                maxTime={endTimes.maxTime}
                component={DatePickerField}
                onChange={(val) => {
                  const min = setHours(setMinutes(new Date(), 0), getHours(new Date()))
                  const newValue = isBefore(val, min) ? min : val
                  const valueToUse = isAdmin ? val : isWorkPlace ? newValue : val
                  const isIntervalAfter =
                    interval.start && valueToUse ? isAfter(valueToUse, interval.start) : false
                  const equality =
                    interval.start && valueToUse ? isEqual(valueToUse, interval.start) : false

                  if (interval.start && (equality || !isIntervalAfter)) {
                    setFieldValue(
                      `${name}[${index}].end`,
                      addMinutes(interval.start, 30)
                    )
                  } else {
                    setFieldValue(`${name}[${index}].end`, valueToUse)
                  }
                }}
              />
            </IntervalBox>
            <Buttons>
              {count > 0 && (
                <BtnWrapper type="button" onClick={() => helpers.remove(index)}>
                  <RemoveIcon color="#f5f5f5" />
                </BtnWrapper>
              )}
              {isDesktop && (
                <BtnWrapper
                  type="button"
                  onClick={() => helpers.insert(index, { start: '', end: '' })}
                >
                  <PlusIcon color="#f5f5f5" />
                </BtnWrapper>
              )}
            </Buttons>
          </IntervalGrid>
        </Grid>
      </Grid>
    </IntervalContainer>
  )
}

export const NoBookingIntervals = ({ helpers }) => {
  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <AddButton
          type="button"
          onClick={() => helpers.push({ start: '', end: '' })}
        >
          <BtnWrapper as="div">
            <PlusIcon color="#f5f5f5" />
          </BtnWrapper>
          <BtnText>{translate('add-interval')}</BtnText>
        </AddButton>
      </Grid>
    </Grid>
  )
}

export default BookingInterval

const IntervalGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  gap: 16px;
  width: 100%;
`

const IntervalContainer = styled.div`
  display: flex;
  width: 100%;
`

const IntervalBox = styled.div`
  display: flex;
  /* justify-content: space-between; */
  flex-direction: column;
  height: 100%;
`

const Buttons = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 24px;
  flex-shrink: 0;
  /* align-items: center; */
`

const BtnWrapper = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  background: #38056C;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s;

  &:not(:last-child) {
    margin-right: 8px;
  }

  &:hover {
    opacity: 0.7;
  }
`

const BtnText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  margin-left: 12px;
  cursor: pointer;
`

const AddButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
  display: flex;
  align-items: center;

  ${media.sm`
        border: 1px solid #38056C;
        width: 100%;
        padding: 10px;
        border-radius: 8px;
    `}
`
