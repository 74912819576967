import { api } from '../api'
import { ApiResponseType } from './types'

export const userAPI = {
	auth({ login, password, workspaceId }: AuthDto) {
		const data = new FormData()
		data.append('login', login)
		data.append('password', password)
		data.append('workspace_id', String(workspaceId))

		return api.post<MeResponse>(`App/Auth/API?action=auth`, data)
	},
	me({ workspaceId }: MeDto) {
		return api
			.get<MeResponse>(`App/Auth/API?action=get_me&id=${workspaceId}`)
			.then((res) => res.data)
	},
	getAvatar({
		userId,
		extension = 'jpg',
		url,
		cachedrop = 111111111,
	}: GetAvatarDto) {
		return api
			.get(
				`media/avatar/${userId}/avatar.SW100H100!default.${extension}?_=${cachedrop}`,
				{
					responseType: 'blob',
					baseURL: url,
				},
			)
			.then((r) => r.data)
	},
	list({ workspaceId, projectId, page = 0 }: UserListDto) {
		const data = new FormData()
		data.append('perpage', '10')
		data.append('page', String(page))

		return api
			.post<UsersListResponse>(
				`project/${workspaceId}/${projectId}/API?action=user_list`,
				data,
			)
			.then((res) => res.data)
	},
	extrafield({ workspaceId, projectId }: UserListDto) {
		return api
			.post<ExtendRuntimeResponse>(
				`project/${workspaceId}/${projectId}/API?action=extend_runtime_lister&intent=ws_user`,
			)
			.then((res) => res.data)
	},
	getUser({ workspaceId, projectId, userId }: GetUserDto) {
		return api
			.post<GetUserResponse>(
				`project/${workspaceId}/${projectId}/API?action=get_user&id=${userId}`,
			)
			.then((res) => res.data)
	},
}

export interface Property {
	property_name: string
	property_value: string
}

export interface Crop {
	name: string
	x1: number
	x2: number
	y1: number
	y2: number
}

export interface Default_image {
	sort: number
	context_name: string
	owner_id: string
	image_id: string
	mime: string
	properties: Property[]
	crops: Crop[]
	valid: boolean
	extension: string
	mime_safe: string
}

export interface IUserData {
	updated_timestamp: string
	protected: boolean
	workspace_id: number
	plugin_data: any
	id: number
	login: string
	display: string
	role: string
	enabled: boolean
	default_image: Default_image
}

interface GetAvatarDto {
	userId: number
	extension?: string
	url: string
	cachedrop?: number | string
}

interface MeDto {
	workspaceId: number
}

interface GetUserDto {
	workspaceId: number
	projectId: number
	userId: number
}

interface UserListDto {
	workspaceId: number
	projectId: number
	page?: number
}

interface AuthDto extends MeDto {
	login: string
	password: string
}

interface MeResponse extends ApiResponseType {
	me_data: MeData
}

interface ExtendRuntimeResponse extends ApiResponseType {
	runtime_fields: RuntimeFieldData[]
}

interface GetUserResponse extends ApiResponseType {
	user: IUserData
}

interface UsersListResponse extends ApiResponseType {
	items: UserData[]
	total: number
}

export type UserData = {
	id: string
	login: string
	display: string
	updated_timestamp: string
}

export interface RuntimeFieldData {
	throw_import: boolean
	fappendmode: boolean
	read_path: string
	write_path: string
	css: string
	hint: string
	uid: string
	type: string
	alias: string
	label: string
	sort: number
	block: string
	mask: number
	attribute: string
	options: {
		email_domain: null
	}
}

export interface MeData {
	ui: {
		workspace_id: 0
		user_id: 0
		login: string
		display: string
		enabled: true
		role_id: string
		role: string
		projects: {
			[key: string]: string
		}
		class_version: string
	}
	root_admin: true
}
