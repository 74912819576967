import { useQuery } from 'react-query'
import { useToast } from '@/components/shared/toast/useToast'
import { useProject } from '@/hooks/useProject'
import { ProjectService } from '../services/project.service'
import {
	formatProjectTreeFlat,
	formatProjectTreeStructured,
} from '@/api/mappers/tree.mapper'
import { useMetadata } from '@/api/hooks/useMetadata'

export const useTree = () => {
	const { workspaceId, projectId } = useProject()
	const { metadata } = useMetadata()
	const { enqueueToast } = useToast()

	const { data, isLoading, refetch } = useQuery(
		['tree', workspaceId, projectId],
		() => ProjectService.getTree({ workspaceId, projectId }),
		{
			enabled: !!workspaceId && !!projectId && !!metadata?.data,
			select: ({ data }) => {
				const tree = formatProjectTreeStructured(data.tree, metadata!.layers)
				const nodes = formatProjectTreeFlat(data.tree, metadata!.layers)
				const layer = nodes.find((node) => node.parent === null)

				return { nodes, layer, tree }
			},
			onError: ({ message }) => {
				enqueueToast({ title: 'Ошибка!', message }, { variant: 'error' })
			},
		},
	)

	return { layers: data, isLoading, refetch }
}
