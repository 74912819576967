import useResponsive from '@/hooks/useResponsive'
import Search, { SearchMobileButon } from './Search'
import SearchV2 from './SearchV2'

const SearchBar = () => {
    const { isMobile } = useResponsive()

    if (isMobile) {
         return <SearchMobileButon />
    }

    return process.env.SEARCH_VERSION == 'v2' ? <SearchV2 /> : <Search />
}

export default SearchBar