import { useTree } from '@/api/hooks/useTree'
import { layerChangeEvent } from '@/components/layout/AppBar/search/SearchItem'
import { SectionType } from '@/containers/SidebarContainer'
import { useSettingsSelector } from '@/hooks/use-settings-selector'
import { useGlobalStore } from '@/stores/globalStore'
import media from '@/ui/media'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import DateSelectorBar from './booking-filter/DateSelectorBar'
import SearchBar from './search/SearchBar'
import UserBar from './user/UserBar'

const Appbar: React.FC = () => {
	const atlasHref = useSettingsSelector((settings) => settings.atlasUrl, '#')

	return (
		<Wrapper>
			<GridWrapper>
				<AtlasButton href={atlasHref}>
					<svg
						width="48"
						height="47"
						viewBox="0 0 48 47"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<circle
							cx="23.2942"
							cy="23.5586"
							r="21.7942"
							stroke="white"
							strokeWidth="3"
						/>
						<circle
							cx="24.7056"
							cy="23.5586"
							r="21.7942"
							stroke="#79FF3A"
							strokeWidth="3"
						/>
						<path
							d="M28.5801 32.5222V30.7411C27.938 31.452 27.1597 32.0195 26.294 32.4082C25.4282 32.7968 24.4936 32.9983 23.5485 33C19.377 33 15 29.8606 15 24.0342C15 17.4844 19.6179 15 23.2931 15C25.2425 15.0122 27.1134 15.7893 28.5212 17.1716V15.4402H32V32.5222H28.5801ZM18.5548 24.0302C18.5548 26.2636 20.2346 29.0177 23.8168 29.0177C27.0011 29.0177 28.4531 26.987 28.4531 26.987V20.9472C27.331 19.6493 25.6813 18.9339 23.8168 18.9339C20.6994 18.9339 18.5548 21.1056 18.5548 24.0248"
							fill="white"
						/>
					</svg>
				</AtlasButton>
				{/* <BackItem name="Вернуться" icon={BackIcon} /> */}
				<SearchBar />
			</GridWrapper>
			<DateSelectorBar />
			<UserBar />
		</Wrapper>
	)
}

export default Appbar

const AtlasButton = styled.a`
	width: 48px;
	height: 48px;

	cursor: pointer;
	text-decoration: none;
	outline: none;
`

const GridWrapper = styled.div`
	display: grid;
	align-items: center;
	grid-template-columns: 60px 1fr;
	gap: 16px;
`

const MenuWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 32px;
`

export const BackItem = ({ name, icon: Icon }) => {
	const { layers } = useTree()
	const { activeLayer, setActiveLayer } = useGlobalStore()
	const { nodes } = layers || {}
	const current = useMemo(
		() => nodes?.find((node) => node.id == activeLayer),
		[nodes, activeLayer],
	)

	const handleLayerChange = () => {
		if (!current || !current.parent) return
		document.dispatchEvent(layerChangeEvent(current.parent))

		setActiveLayer(Number(current.parent))
	}

	if (!current || !current.parent) return <div />

	return (
		<Item onClick={handleLayerChange}>
			<Icon />
			<p>{name}</p>
		</Item>
	)
}

interface MenuItemProps {
	name: string
	selector: SectionType
	icon: React.ElementType
}

const LayerItem: React.FC<MenuItemProps> = ({ name, selector, icon: Icon }) => {
	const stateSelector = useGlobalStore((state) => state.selector)
	const setSelector = useGlobalStore((state) => state.setSelector)

	const isActive = selector === stateSelector
	const onChange = () => setSelector(isActive ? null : selector)

	return (
		<Item onClick={onChange}>
			<Icon />
			<p>{name}</p>
		</Item>
	)
}

const Item = styled.div`
	//width: 100%;
	height: 75px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	cursor: pointer;
	//padding: 2rem 1rem;
	position: relative;
	transition: background 0.3s, color 0.3s;

	p {
		font-weight: 400;
		font-size: 10px;
		line-height: 16px;
		text-align: center;
		margin-top: 0.8rem;
	}

	.hover-icon,
	.hover-color,
	.hover-alt,
	.hover-fill {
		transition: all 0.3s;
	}

	img {
		z-index: 2;
		user-select: none;
	}

	background: ${(props) => props.theme.palette.background.primary};
	color: ${(props) => props.theme.palette.text.primary};

	.hover-icon {
		fill: transparent;
	}

	.hover-fill {
		fill: ${(props) => props.theme.palette.text.primary};
	}

	.hover-color {
		fill: ${(props) => props.theme.palette.text.primary};
		stroke: ${(props) => props.theme.palette.text.primary};
	}

	.hover-alt {
		fill: ${(props) => props.theme.palette.text.primary};
		stroke: ${(props) => props.theme.palette.background.primary};
	}
`

const Wrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	display: grid;
	align-items: center;
	grid-template-columns: 1fr 1fr 1fr;
	column-gap: 32px;
	padding: 0 20px;
	height: 75px;
	box-shadow: 4px 0px 40px rgba(84, 84, 84, 0.25);
	/* border-radius: 0px 0px 8px 8px; */
	z-index: 2001;
	background: ${(props) => props.theme.palette.background.primary};
	color: ${(props) => props.theme.palette.text.primary};

	${media.lg`
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 16px;
        padding: 0 10px;
        border-radius: 0px;
    `}
`
