export type MergeMessageParams = {
	seat: string
	created: string
	reason: string
	message: string
}

export const mergeMessage = (params: MergeMessageParams) => {
	return [
		`Номер места: ${params.seat}`,
		`Запросил: ${params.created}`,
		`Причина: ${params.reason}`,
		`Комментарий: ${params.message}`,
	].join('.<br />')
}
