import { useLayerInfo } from '@/api/hooks/useLayerInfo';
import { useMetadata } from '@/api/hooks/useMetadata';
import Close from '@/components/Close';
import Modal from '@/components/Modal/Modal';
import { DefaultButton } from '@/components/ui/button/DefaultButton';
import { useGlobalStore } from '@/stores/globalStore';
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

const format = (str: string) => str || ''

export const linkLayerInfoProperties = (node, meta) => {
  if (!node || !meta) return [];
  const keys = Object.keys(node);

  const data = keys.map(key => meta[key]).filter(v => v).find(item => item.fields)?.fields;
  const nodeData = keys.map(key => node[key]).filter(v => v).find(item => item.fields)?.fields;

  if (!data || !nodeData) return [];

  const props = data.map(item => {
    const info = nodeData.find(n => n.id == item.field_id);

    if (!info || typeof info.value === 'object') return null;

    return {
      name: String(info.name),
      value: info.name.startsWith('#') ? item.value : String(item.value)
    };
  }).filter(v => v);

  return props;
};


const LayerInfoModal: React.FC = () => {

    // data selectors
    const layerModal = useGlobalStore(state => state.layerModal)
    const setLayerModal = useGlobalStore(state => state.setLayerModal)

    // fetch data hook
    const { data } = useLayerInfo(layerModal)
    const { metadata } = useMetadata()

    const currentNode = useMemo(() => {
        if (data && metadata && metadata.layers) {
            return metadata.layers[data.info.type_uid]
        }
        return null
    }, [data, metadata])

    const props = linkLayerInfoProperties(currentNode?.plugin_data, data?.info?.plugin_data)
    const onClose = () => setLayerModal(null)
    const place = data?.info.parent_name.join('. ') || ''

    return (
        <Modal
            isOpen={Boolean(layerModal)}
            onClose={onClose}
            maxWidth={540}
        >
            <Header>
                <Title>Информация об уровне</Title>
                <Close color="#000" onClick={onClose} />
            </Header>

            <div>
                <Content>
                    {data?.info && (
                        <Wrapper>
                            {data.info.name && (
                                <Section>
                                    <Item $bold>Наименование</Item>
                                    <Item>{format(data.info.name)}</Item>
                                </Section>
                            )}

                            {currentNode?.name && (
                                <Section>
                                    <Item $bold>Тип</Item>
                                    <Item>{format(currentNode?.name)}</Item>
                                </Section>
                            )}

                            {place && (
                                <Section>
                                    <Item $bold>Расположение</Item>
                                    <Item>{format(place)}</Item>
                                </Section>
                            )}

                            {data.info.info && (
                                <Section>
                                    <Item $bold>Описание</Item>
                                    <Item>{format(data.info.info)}</Item>
                                </Section>
                            )}

                            {props.map(property => (
                                <Section key={property.uid}>
                                    <Item $bold>{property.name}</Item>
                                    <Item>{property.value}</Item>
                                </Section>
                            ))}
                        </Wrapper>
                    )}
                </Content>

                <ControlsWrapper>
                    <DefaultButton onClick={onClose}>Закрыть</DefaultButton>
                </ControlsWrapper>
            </div>
        </Modal>
    )
}

export default LayerInfoModal

const Wrapper = styled.div`
    margin: 1rem 0;
    height: 100%;
    display: flex;
    flex-direction: column;
`

const Section = styled.div`
    display: flex;

    &:not(:last-child) {
        margin-bottom: 12px;
    }
`

const Item = styled.div<{ $bold?: boolean }>`
    ${({ $bold }) => $bold && css`
        font-weight: 700;
        min-width: 150px;
        padding-right: 16px;
    `}
`

const Content = styled.div`
    margin-top: 24px;
`

const ControlsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 24px;
`


const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const Title = styled.div`
    font-weight: 600;
    font-size: 24px;
    line-height: 16px;
    color: #2C2C2C;
`