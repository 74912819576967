import { API } from '@/api'
import { isNonNullable } from '@/components/layout/AppBar/user/User'
import {
	defaultMapPointImage,
	getImageLink,
} from '@/components/shared/map/point/Point'
import { useGlobalStore } from '@/stores/globalStore'
import { useMapStore } from '@/stores/mapStore'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Group, Image, Rect, Text } from 'react-konva'
import useImage from 'use-image'
import { usePointCoordinates } from '@/components/shared/map/point/basic/lib/use-point-coordinates'

export type CrossOriginType = 'anonymous' | 'use-credentials' | undefined

const useUserMapAvatar = (
	userId: number | string | undefined | null,
	apiUrl: string,
) => {
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [data, setData] = useState<string | null>(null)

	const fetchImage = async () => {
		try {
			setIsLoading(true)
			const response = await API.user.getAvatar({
				userId: Number(userId),
				url: apiUrl,
			})
			const objectData = response ? URL.createObjectURL(response) : null
			setData(objectData)
		} catch (e) {
			console.error('failed to fetch user map avatar')
		}
		setIsLoading(false)
	}

	useEffect(() => {
		if (isNonNullable(userId) && Number(userId) > 0) {
			fetchImage()
		}
	}, [userId])

	return {
		src: data,
		isLoading,
	}
}

const useMapAvatar = (bookingData, node, url, sourceType) => {
	const userAvatar = useUserMapAvatar(bookingData?.user_id, url)
	const imgSrc = bookingData
		? userAvatar.src
		: getImageLink(node?.icon, url) || getImageLink(defaultMapPointImage, url)
	const [pointImg] = useImage(imgSrc || '', sourceType)

	return { image: pointImg }
}

const BasicPoint = (props) => {
	const {
		colors,
		point,
		options,
		hasDepartment,
		bookingData,
		available,
		node,
		url,
		sourceType,
		opacity,
	} = props

	const layers = useMapStore((state) => state.layers)
	const setSeat = useGlobalStore((state) => state.setSeat)
	const setSeatEmployee = useGlobalStore((state) => state.setSeatEmployee)
	const [width, height] = useMapStore((state) => state.size)
	const setTooltip = useMapStore((state) => state.setTooltip)

	const textRef = useRef<any>(null)
	const groupRef = useRef<any>(null)

	const { id, x, y, name, type_uid, plugin_data } = point
	const { fontSize, color, borderWidth, wrapText, labelSize } = options
	const size_k = point.size_k * node.size_k

	const isEmployeeVisible = layers['employees']
	const { image } = useMapAvatar(
		isEmployeeVisible ? bookingData : null,
		node,
		url,
		sourceType,
	)
	const bookingCustomData =
		bookingData?.booking_custom_data?.replace(/[,.;]/g, '\n') ?? ''
	const username = bookingData?.display
		? bookingData.display.split(' ').slice(0, 2).join(' ')
		: null
	const text = isEmployeeVisible ? username || name : name

	const tempText = wrapText ? text.split(' ').join('\n') : text
	const displayText = bookingCustomData
		? [tempText, bookingCustomData].join('\n')
		: tempText

	const onSelect = useCallback(() => {
		setSeat(Number(point.id))
		if (bookingData) {
			setSeatEmployee(Number(bookingData.user_id))
		} else {
			setSeatEmployee(null)
		}
	}, [setSeat, setSeatEmployee, bookingData, point])

	const onMouseEnterHandler = React.useCallback((e) => {
		const container = e.target.getStage()?.container()

		if (container) {
			container.style.cursor = 'pointer'
		}
	}, [])

	const onMouseLeaveHandler = React.useCallback((e) => {
		const container = e.target.getStage()?.container()

		if (container) {
			container.style.cursor = 'default'
		}
	}, [])

	const onGroupMouseEnterHandler = React.useCallback(
		(e) => {
			setTooltip(name)
		},
		[setTooltip, name],
	)

	const onGroupMouseLeaveHandler = React.useCallback(
		(e) => {
			setTooltip(null)
		},
		[setTooltip],
	)

	const getColor = useMemo(() => {
		if (!hasDepartment || !Object.keys(colors).length) return node?.border
		const color = colors[hasDepartment]

		if (!hasDepartment || !color) return node?.border
		return color
	}, [point, node, hasDepartment, colors])

	const opacityValue = parseInt(opacity)
	const pointOpacity = opacityValue ? opacityValue : available ? 1 : 0.3
	const { point: pointCoordinates, text: textCoordinates } =
		usePointCoordinates(width, height, labelSize, size_k, x, y, textRef)
	const shapeSize = width * labelSize * size_k
	const radiusPercentage = useMemo(
		() => shapeSize * ((node?.radius || 100) / 100),
		[shapeSize, node?.radius],
	)

	useEffect(() => {
		if (!groupRef.current) return
		groupRef.current.on('mouseenter', onGroupMouseEnterHandler)
		groupRef.current.on('mouseleave', onGroupMouseLeaveHandler)
	}, [groupRef.current])

	return (
		<Group
			x={pointCoordinates.x}
			y={pointCoordinates.y}
			offsetX={shapeSize / 2}
			offsetY={shapeSize / 2}
			onClick={onSelect}
			onTap={onSelect}
			listening={true}
		>
			<Group
				onMouseEnter={(e) => {
					onMouseEnterHandler(e)
					onGroupMouseEnterHandler(e)
				}}
				onMouseLeave={(e) => {
					onMouseLeaveHandler(e)
					onGroupMouseLeaveHandler(e)
				}}
				opacity={pointOpacity}
				id={'point' + point.id}
				ref={groupRef}
			>
				<Rect
					width={shapeSize}
					height={shapeSize}
					stroke={getColor}
					strokeWidth={fontSize * borderWidth}
					fill={node?.background}
					cornerRadius={radiusPercentage}
					shadowForStrokeEnabled={false}
					perfectDrawEnabled={false}
					listening={available}
				/>
				{image && (
					<Image
						image={image}
						width={shapeSize}
						height={shapeSize}
						cornerRadius={radiusPercentage}
					/>
				)}
			</Group>
			<Text
				ref={textRef}
				text={displayText}
				align="center"
				fontSize={fontSize}
				fill={color}
				x={textCoordinates.x}
				y={textCoordinates.y}
				listening={available}
				perfectDrawEnabled={false}
			/>
		</Group>
	)
}

export default BasicPoint
