import {useId} from "react";
import styled from "styled-components";

const Checkbox = ({ label = '', checked = false, onChange = (state: boolean) => {} }) => {
    const checkboxId = useId()

    return (
        <CheckboxWrapper>
            <input
                className="input"
                type="checkbox"
                id={checkboxId}
                checked={checked}
                onChange={event => onChange(event.target.checked)}
            />
            <label
                className="label"
                htmlFor={checkboxId}
                onClick={(event: any) => {
                    event.preventDefault()
                    event.currentTarget.control.click()
                }}
            >
               <div className="indicator">
                   {checked ? (
                       <svg className="checkmark" viewBox="0 0 100 100">
                           <polyline points="25.5, 53.5 39.5, 67.5 72.5, 34.5" />
                       </svg>
                   ) : null}
               </div>
               <div>{label}</div>
            </label>
        </CheckboxWrapper>
    )
}

export default Checkbox

const CheckboxWrapper = styled.div`
  .label {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .indicator {
    position: relative;
    content: ' ';
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-right: 8px;
    border-radius: 4px;
    border: 1px solid #38056C;
  }

  .checkmark {
    position: absolute;
    left: 0;
    top: 0;
    width: 17px;
    height: 17px;
    fill: none;
    stroke-width: 8px;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: #000;
  }
  
  .input {
    opacity: 0;
    position: absolute;
    width: 0.1px;
    height: 0.1px;
  }
`