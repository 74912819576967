import React, { useState } from 'react'

type DefaultValue = {
    sort: string
    direction: 0 | 1
}

const useSorting = (val?: DefaultValue) => {
    const [sort, setSort] = useState<string>(val?.sort ?? '')
    const [direction, setDirection] = useState<0 | 1>(val?.direction ?? 0)

    const handleSort = (newSort) => {
        if (newSort === sort) {
            setDirection(direction ? 0 : 1)
        } else {
            setDirection(0)
            setSort(newSort)
        }
    }

    return {
        sort, direction, handleSort
    }
}

export default useSorting