import { BASE_DOMAIN } from '@/api';
import { useProject } from '@/hooks/useProject';
import { useGlobalStore } from '@/stores/globalStore'
import { useUserStore } from '@/stores/userStore';
import React, { useEffect } from 'react'
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

const LogoutPage = () => {
    const project = useGlobalStore(state => state.project)
    const setUser = useUserStore(state => state.setUser)
    const setAuthenticated = useUserStore(state => state.setAuthenticated)
    const {workspaceId, projectId} = useProject()

    const navigate = useNavigate()
    const queryClient = useQueryClient()

    useEffect(() => {
        localStorage.removeItem('token')
     


        const isSAML = localStorage.getItem('saml')

        if (isSAML) {
            localStorage.removeItem('saml')
            const redirectTo = BASE_DOMAIN + `/App/Auth/Logout?workspace_id=${workspaceId}&return_uri=${location.origin}/project/${workspaceId}/${projectId}`
            
            location.href = redirectTo
        } else {
            setUser(null)
            setAuthenticated(false)
            queryClient.removeQueries({ predicate: (query) => !query.queryKey.includes('settings') });

            let redirectTo = `/`

            if (project && project.workspaceId && project.projectId) {
                redirectTo = `/login?workspace_id=${project.workspaceId}&project_id=${project.projectId}`
            }
    
            navigate(redirectTo)
        }

    }, [])

    return null
}

export default LogoutPage