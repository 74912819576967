import React from 'react'
import { SectionType } from '@/containers/SidebarContainer';
import { useGlobalStore } from '@/stores/globalStore';
import { useProjectStore } from '@/stores/projectStore';
import MenuItem from './MenuItem';

interface SelectorMenuItemProps {
    name: string
    selector: SectionType
    icon: React.ElementType
}

const SelectorMenuItem: React.FC<SelectorMenuItemProps> = ({ name, selector, icon }) => {
    // data selectors
    const stateSelector = useGlobalStore(state => state.selector)
    const setSelector = useGlobalStore(state => state.setSelector)
    const fullMode = useProjectStore(state => state.fullMode)
    const simpleModeSelector = selector === 'bookings' || selector === 'employees' || selector === 'reports'

    const isActive = selector === stateSelector
    const onChange = () => setSelector(isActive ? null : selector)

    if (!fullMode && simpleModeSelector) return null

    return <MenuItem icon={icon} name={name} isActive={isActive} onClick={onChange} />   
}

export default SelectorMenuItem