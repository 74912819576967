import axios from 'axios'

export const MailService = {
	async sendMail(createMaintenanceDto: any) {
		const data = new FormData()
		data.append('subject', String(createMaintenanceDto.subject))
		data.append('message', String(createMaintenanceDto.message))
		data.append('recipient', String(createMaintenanceDto.recipient))
		data.append('path', String(createMaintenanceDto.path))

		return axios.post<any>(`/mailto/email.php`, data)
	},
}
