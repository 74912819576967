import { getMaintenanceOptions } from './get-maintenance-options'
import { useSettingsSelector } from '@/hooks/use-settings-selector'
import { useMetadata } from '@/api/hooks/useMetadata'
import { useMemo } from 'react'
import { useUserWithFields } from '@/api/hooks/use-user-with-fields'

type UseMaintenanceProps = {
	type?: string
	seat?: string
}

export const useMaintenance = ({ type, seat }: UseMaintenanceProps) => {
	const { metadata } = useMetadata()
	// const {tree} = useTree()

	const nodesMeta = metadata?.nodes || []
	const currentNodeType = useMemo(
		() => nodesMeta.find((meta) => meta.uid === type)?.name,
		[nodesMeta, type],
	)

	const maintenance = useSettingsSelector(
		(settings) => settings.maintenance,
		{},
	)

	const userData = useUserWithFields()
	const userEmail = useMemo(() => {
		return userData?.fields['email'] || userData?.fields['mail']
	}, [userData])

	const options = getMaintenanceOptions({
		options: maintenance,
		type: currentNodeType,
	})

	return {
		options,
		user: userData,
		userEmail,
	}
}
