import { RefObject, useMemo } from 'react'
import Konva from 'konva'

const getMapCoordinates = (value: number, percent: number) =>
	value * (percent / 100)

type GetTextCoordinatesArguments = {
	width: number
	textWidth?: number
	labelSize: number
	multiplier: number
}

const getTextCoordinates = ({
	width,
	textWidth = 0,
	labelSize,
	multiplier,
}: GetTextCoordinatesArguments) => {
	return {
		x:
			-textWidth / 2 + (width * labelSize * multiplier) / 2 ||
			(width * labelSize * multiplier) / 2,
		y: width * 1.1 * labelSize * multiplier,
	}
}

export const usePointCoordinates = (
	width: number,
	height: number,
	labelSize: number,
	size_k: number,
	x: number,
	y: number,
	textRef: RefObject<Konva.Text>,
) => {
	const pointCoordinates = useMemo(
		() => ({
			x: getMapCoordinates(width, x),
			y: getMapCoordinates(height, y),
		}),
		[width, height, x, y],
	)

	const textCoordinates = useMemo(
		() =>
			getTextCoordinates({
				width,
				textWidth: textRef.current?.textWidth,
				labelSize,
				multiplier: size_k,
			}),
		[labelSize, textRef.current?.textWidth, width, size_k],
	)
	return {
		point: pointCoordinates,
		text: textCoordinates,
	}
}
