import { useNodes } from '@/api/hooks/useNodes'
import { NodeElement } from '@/api/project'
import Close from '@/components/Close'
import Grid from '@/components/Grid'
import { SearchButton } from '@/components/layout/AppBar/search/Search'
import Modal from '@/components/Modal/Modal'
import { DefaultButton } from '@/components/ui/button/DefaultButton'
import { PrimaryButton } from '@/components/ui/button/PrimaryButton'
import {
	ControlsWrapper,
	FormControls,
} from '@/components/ui/form/FormControls'
import FormLoader from '@/components/ui/form/FormLoader'
import useResponsive from '@/hooks/useResponsive'
import { translate } from '@/i18n'
import { useProjectStore } from '@/stores/projectStore'
import { RolesEnum, useUserStore } from '@/stores/userStore'
import { Input } from '@/ui/components/Field/Input'
import { SelectInput } from '@/ui/components/Field/Select'
import Pagination from '@/ui/components/Pagination/Pagination'
import { useFormikContext } from 'formik'
import { debounce } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import MobilePlaceItem from './MobilePlaceItem'
import { BookingTypeEnum } from '@/api/bookings'
import { useSettingsSelector } from '@/hooks/use-settings-selector'
import { checkTreeHidden } from '@/components/layout/Sidebar/Layers/TreeItem'
import { useMetadata } from '@/api/hooks/useMetadata'
import { useHiddenLayers } from '@/api/hooks/useHiddenLayers'

type ReportModalProps = {
	isOpen: boolean
	onClose: () => void
	setFieldValue: any
}

const useSeatsWithCategories = ({ name, parent }) => {
	const categories = useUserStore((state) => state.categories)
	const role = useUserStore((state) => state.role)
	const selectPerPage = useSettingsSelector(
		(settings) => settings.selectSeatPerPage,
		6000,
	)
	const { hiddenLayers } = useHiddenLayers()
	const hiddenLayersIds =
		role === RolesEnum.Admin ? [] : (hiddenLayers ?? []).map(({ id }) => id)

	console.log(hiddenLayersIds)
	// const userCategories = categories
	const userCategories = role === RolesEnum.Admin ? [undefined] : categories

	const queries = userCategories.map((category) =>
		useNodes({
			page: 0,
			perPage: selectPerPage,
			sort: 'name',
			direction: 0,
			bookable: 1,
			categories: category,
		}),
	)
	const isLoading = queries.some((query) => query.isLoading)
	const availableNodes = useMemo(() => {
		if (!queries || !queries.length) {
			return {
				data: [],
				total: 0,
			}
		}

		const queryData = queries
			.filter((v) => v.data?.items)
			.reduce(
				(acc, data) => [...acc, ...(data.data?.items as any)],
				[] as any[],
			)

		const filteredByName =
			name && name !== ''
				? queryData.filter((item) =>
						item.name.toLowerCase().includes(name.toLowerCase()),
				  )
				: queryData

		const filteredByParent =
			parent && parent !== 0
				? filteredByName.filter((item) => item.parent == parent)
				: filteredByName

		const result = filteredByParent.filter(
			(item) => !hiddenLayersIds.includes(Number(item.parent)),
		)

		return {
			data: result,
			total: result.length || 0,
		}
	}, [queries, isLoading, name])

	return {
		data: {
			items: availableNodes.data,
			total: availableNodes.total,
		},
		isLoading,
	}
}

const SelectSeatModal: React.FC<ReportModalProps> = ({ isOpen, onClose }) => {
	const [currentPage, setCurrentPage] = useState<number>(1)
	const [place, setPlace] = useState<any>(null)
	const [parent, setParent] = useState<string>('')
	const [name, setName] = useState<string>('')
	const intl = useIntl()

	const { values, setFieldValue } = useFormikContext<any>()
	const { isMobile } = useResponsive()
	const { metadata } = useMetadata()
	const role = useUserStore((state) => state.role)
	const nodes = useProjectStore((state) => state.nodes)
	const { hiddenLayers } = useHiddenLayers()
	const hiddenLayersIds =
		role === RolesEnum.Admin ? [] : (hiddenLayers ?? []).map(({ id }) => id)

	const floorNodes =
		nodes.filter((n) =>
			n.ownView && role === RolesEnum.Admin
				? true
				: !checkTreeHidden(n, metadata?.layers) &&
				  !hiddenLayersIds.includes(Number(n.parent)),
		) || []

	const { data, isLoading } = useSeatsWithCategories({
		name,
		parent,
	})

	const availableNodes = useMemo(() => {
		if (!data || !data.items || !data.items.length) return []

		return data.items
	}, [data, isLoading])

	const handleSelection = async () => {
		if (place) {
			setFieldValue('seat', {
				id: place.id,
				name: [place.place, place.spot].join('. '),
				type: place.type,
			})
			setFieldValue('dates', [])
			setFieldValue('type', String(BookingTypeEnum.Regular))
		}
		onClose()
	}

	useEffect(() => {
		if (isOpen) {
			setPlace({
				id: values.seat.id || '',
				name: values.seat.name || '',
				type: values.seat.type || '',
			})
			setParent('')
			setCurrentPage(1)
			setName('')
		}
	}, [isOpen])

	const handleNameChange = (e) => setName(e?.target?.value)
	const handleParentChange = (e) => setParent(e?.target?.value)

	const debouncedNameResponse = useMemo(() => {
		return debounce(handleNameChange, 500)
	}, [])

	useEffect(() => {
		return () => debouncedNameResponse.cancel()
	}, [])

	return (
		<Modal isOpen={isOpen} onClose={onClose} maxWidth={900}>
			<Header>
				<Title>{translate('select-location')}</Title>
				<Close color="#000" onClick={onClose} />
			</Header>
			<div>
				<Grid>
					<Filters>
						<FiltersItem>
							<SelectInput
								$fullWidth
								onChange={handleParentChange}
								value={parent}
							>
								<option value="">{translate('all-levels')}</option>
								{floorNodes.map((node) => (
									<option key={node.id} value={node.id}>
										{node.name}
									</option>
								))}
							</SelectInput>
						</FiltersItem>
						<FiltersItem>
							<Input
								$fullWidth
								placeholder={intl.formatMessage({ id: 'search-by-name' })}
								onChange={debouncedNameResponse}
								// value={name}
							/>
						</FiltersItem>
						<FiltersSearch>
							<SearchButton />
						</FiltersSearch>
					</Filters>

					{!isMobile && (
						<Grid.RowHeader $cols="20px 1fr 1fr 1fr">
							<Grid.Item />
							<Grid.Item>Уровень</Grid.Item>
							<Grid.Item>{translate('place')}</Grid.Item>
							<Grid.Item>{translate('type')}</Grid.Item>
						</Grid.RowHeader>
					)}

					{availableNodes.length ? (
						<>
							{!isMobile &&
								availableNodes
									.slice((currentPage - 1) * 20, currentPage * 20)
									.map((place) => (
										<PlaceItem
											key={place.id}
											place={place}
											handleSelection={setPlace}
										/>
									))}
							{isMobile &&
								availableNodes
									.slice((currentPage - 1) * 20, currentPage * 20)
									.map((placeData) => (
										<MobilePlaceItem
											key={placeData.id}
											place={placeData}
											selected={place}
											handleSelection={setPlace}
										/>
									))}

							<PaginationWrapper>
								<Pagination
									inverse
									currentPage={currentPage}
									total={data.total}
									itemsPerPage={20}
									handlePageChange={setCurrentPage}
								/>
							</PaginationWrapper>
						</>
					) : (
						<NotFound>{translate('no-results')}</NotFound>
					)}

					<FormLoader isLoading={isLoading} />

					<ControlsWrapper>
						<FormControls>
							<PrimaryButton $fullWidth type="submit" onClick={handleSelection}>
								{translate('select')}
							</PrimaryButton>
							<DefaultButton $fullWidth onClick={onClose} type="button">
								{translate('cancel')}
							</DefaultButton>
						</FormControls>
					</ControlsWrapper>
				</Grid>
			</div>
		</Modal>
	)
}

export default SelectSeatModal

const NotFound = styled.div`
	width: 100%;
	text-align: center;
	padding: 12px 0;
`

const Filters = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 60px;
	column-gap: 14px;
	padding: 6px 0 14px 0;
`
const FiltersSearch = styled.div``
const FiltersItem = styled.div``

const PlaceItem: React.FC<{
	place: NodeElement
	handleSelection: React.Dispatch<any>
}> = ({ place, handleSelection }) => {
	const { nodes } = useProjectStore()
	const placename = nodes.find((tree) => tree.id == Number(place.parent))

	return (
		<Grid.Row
			$cols="20px 1fr 1fr 1fr"
			as="label"
			$label
			onClick={() => {
				handleSelection({
					place: placename?.name || 'Не указано',
					spot: place.name,
					id: place.id,
					type: place.type_name,
				})
			}}
		>
			<Grid.Item>
				<input type="radio" name="employee" value="1" />
			</Grid.Item>
			<Grid.Item>{placename ? placename.name : 'Не указано'}</Grid.Item>
			<Grid.Item>{place.name}</Grid.Item>
			<Grid.Item>{place.type_name}</Grid.Item>
		</Grid.Row>
	)
}

const PaginationWrapper = styled.div`
	padding: 2rem 0 0;
`

const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`

const Title = styled.div`
	font-weight: 500;
	font-size: 2.4rem;
	line-height: 2.4rem;
	color: #000000;
`
