import { mergeMessage, MergeMessageParams } from './merge-mail-message'

type CreateMailParamsType = {
	reason: string
	messageParams: MergeMessageParams
	recipient: string
	path: string[]
}

export const createMailParams = ({
	reason,
	messageParams,
	recipient,
	path,
}: CreateMailParamsType) => {
	return {
		subject: reason,
		message: mergeMessage(messageParams),
		path,
		recipient,
	}
}
