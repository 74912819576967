import { useExcludedLayerIds } from '@/api/hooks/useHiddenLayers'
import { Grid } from '@/components/Grid'
import { useGlobalStore } from '@/stores/globalStore'
import { useMapStore } from '@/stores/mapStore'
import { formatPhoneNumber } from '@/utils/helpers/phone.helpers'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import InfoIcon from '../icons/InfoIcon'
import PointIcon from '../icons/PointIcon'
import MobileEmployeeItem from './MobileEmployeeItem'


export const EmployeeMobileListItem: React.FC<any> = ({ user, bookings }) => {
    const { setEmployee } = useGlobalStore()
    const { id, display, fields } = user
    const setSeat = useGlobalStore(state => state.setSeat)
    const setSeatEmployee = useGlobalStore(state => state.setSeatEmployee)
    const setActiveLayer = useGlobalStore(state => state.setActiveLayer)
    const setZoomSeat = useMapStore(state => state.setZoomSeat)
    const setSelector = useGlobalStore((state) => state.setSelector)
	const hiddenLayers = useExcludedLayerIds()
    const book = useMemo(() => bookings.find(b => b['user_id'] == id && !hiddenLayers.includes(Number(b['parent_layer_id']))), [bookings, id, hiddenLayers])


    const openUser = () => setEmployee(id)
    const handleSeatView = (e) => {
        e.preventDefault()
        if (!book) return

        setActiveLayer(Number(book.parent_layer_id))
        setSeatEmployee(null)
        setSeat(Number(book.bookable_id))
        setZoomSeat({ seat: Number(book.bookable_id), parent: Number(book.parent_layer_id) })
        setSelector(null)
    }

    return (
        <MobileEmployeeItem
            name={display}
            fields={fields}
            openUser={openUser}
            booking={!!book}
            handleSeatView={handleSeatView}
        />
    )
}

const EmployeeListItem: React.FC<any> = ({ template, columns, item , bookings}) => {
    const { setEmployee } = useGlobalStore()
    const { id, data } = item
    const setSeat = useGlobalStore(state => state.setSeat)
    const setSeatEmployee = useGlobalStore(state => state.setSeatEmployee)
    const setActiveLayer = useGlobalStore(state => state.setActiveLayer)
    const setZoomSeat = useMapStore(state => state.setZoomSeat)
	const hiddenLayers = useExcludedLayerIds()
    const book = useMemo(() => bookings.find(b => b['user_id'] == id && !hiddenLayers.includes(Number(b['parent_layer_id']))), [bookings, id, hiddenLayers])

    const openUser = () => setEmployee(id)
    const handleSeatView = (e) => {
        e.preventDefault()
        if (!book) return

        setActiveLayer(Number(book.parent_layer_id))
        setSeatEmployee(null)
        setSeat(Number(book.bookable_id))
        setZoomSeat({ seat: Number(book.bookable_id), parent: Number(book.parent_layer_id) })
    }

    

    return (
        <Grid.Row $cols={template}>
            {data.map(({ label, value, type }, idx) => (
                <Grid.Item key={idx}>
                    {type === 'phone' ? formatPhoneNumber(value) : value}
                </Grid.Item>
            ))}
            {/* <Grid.Item>{item.id}</Grid.Item>
            <Grid.Item>{item.name}</Grid.Item>

            <Grid.Item>{item.department || 'Не указано'}</Grid.Item>
            <Grid.Item>{item.email}</Grid.Item>
            <Grid.Item>{item.phone}</Grid.Item> */}
            <Grid.Item>
                <Actions>
                    {book && (
                        <div>
                            <a href="#"onClick={handleSeatView}>
                                <PointIcon />
                            </a>
                        </div>
                    )}
                    <div>
                        <a href="#" onClick={openUser}>
                            <InfoIcon />
                        </a>
                    </div>
                </Actions>
            </Grid.Item>
        </Grid.Row>
    )
}

export default EmployeeListItem

const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 8px;
    gap: 4px;
`