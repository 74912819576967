import { BookingTypeEnum } from '@/api/bookings';
import { useSettingsSelector } from '@/hooks/use-settings-selector';
import { translate } from '@/i18n';
import { InputField } from '@/ui/components/Field/Input';
import { FormLabel } from '@/ui/components/Form/FormLabel';
import Grid from '@/ui/components/Grid';
import { Field, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Portal } from 'react-portal';
import { ModalSelectInputField } from '../shared/ModalSelectInputField';
import SelectSeatModal from './SelectSeatModal';

export const SelectSeatField = () => {
    const [isOpen, setOpen] = useState(false)
    const { setFieldValue, values } = useFormikContext<{ seat: { id: string, name: string, type: string } }>()
    const intl = useIntl()
    const locker = useSettingsSelector((state) => state.locker)

    useEffect(() => {
        if (locker === values.seat.type) {
            setFieldValue('type', String(BookingTypeEnum.Constant))
        } else {
            setFieldValue('type', String(BookingTypeEnum.Regular))
        }
    }, [values.seat.type])

    return (
        <>
            <Grid item xs={12} md={4} onClick={() => setOpen(true)}>
                <FormLabel>{translate('choose-location')}</FormLabel>
                <Field
                    name="seat"
                    $fullWidth
                    type="text"
                    placeholder={intl.formatMessage({ id: 'location' })}
                    // disabled
                    style={{ pointerEvents: 'none' }}
                    component={ModalSelectInputField} />
            </Grid>
            <Portal>
                <SelectSeatModal isOpen={isOpen} setFieldValue={setFieldValue} onClose={() => setOpen(false)} />
            </Portal>
        </>
    )
}

export const SelectPlace = () => {
    const [isOpen, setOpen] = useState(false)
    const { setFieldValue } = useFormikContext<{ seat: any }>()

    return (
        <>
            <Grid item xs={12} md={4} onClick={() => setOpen(true)}>
                <FormLabel>Выберите место</FormLabel>
                <Field name="spot" $fullWidth type="text" placeholder="место" disabled component={InputField} />
            </Grid>
            <Portal>
                <SelectSeatModal isOpen={isOpen} setFieldValue={setFieldValue} onClose={() => setOpen(false)} />
            </Portal>
        </>
    )
}