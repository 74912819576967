import { IUserData, RuntimeFieldData } from '@/api/services/user.service'
import { useUserStore } from '@/stores/userStore'
import { useUser } from '@/api/hooks/useUser'
import { useUserCardFields } from '@/api/hooks/useUserCardFields'
import { useMemo } from 'react'

type PluginDataObject = { [key: string]: number | string | boolean }

/**
 * Функция для объединения данных в plugin_data, возвращает объект с полями пользователя.
 *
 * @param arr Массив объектов для объединения.
 * @returns Массив объектов типа MergedObject.
 */
function mergeUserPluginDataObjects(arr: PluginDataObject[]): PluginDataObject {
	return arr.reduce((acc, obj) => {
		return { ...acc, ...obj }
	}, {})
}

const fillUserWithUserData = (user: IUserData, fields: RuntimeFieldData[]) => {
	const pluginData = Object.values(user.plugin_data || {}) as Record<
		string,
		number | string | boolean
	>[]
	const userPluginFields = mergeUserPluginDataObjects(pluginData)
	const fieldsData = {}

	fields.forEach((field) => {
		const fieldValue = userPluginFields[field.uid]
		if (!fieldValue) return

		fieldsData[field.alias] = fieldValue
	})

	return {
		...user,
		fields: fieldsData,
	}
}

export const useUserWithFields = () => {
	const me = useUserStore((state) => state.user)
	const { data } = useUser(me?.user_id)
	const { data: fieldsData } = useUserCardFields()
	const user = data?.user
	const userFields = fieldsData?.fields || []

	return useMemo(() => {
		if (!user) return null
		return fillUserWithUserData(user, userFields)
	}, [user, userFields])
}
