import { RolesEnum, useUserStore } from '@/stores/userStore'

export const floorIsApproach = (value: string) => {
	const role = useUserStore.getState().role
	const isNotLocker = value.search(/^\d\.[\d]+-\d\.[\d]+$/) === -1
	const isUser = role === RolesEnum.User
	const isNotParkingTwo = value.toLowerCase() !== 'парковка 2'

	if (isUser) {
		return isNotParkingTwo && isNotLocker
	}

	return isNotLocker
}
