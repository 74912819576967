import { sizes } from '@/ui/media'
import { DefaultTheme } from 'styled-components'

export const blueTheme: DefaultTheme = {
    grid: {
        gutter: 8,
        breakpoints: sizes
    },
    palette: {
        default: {
            main: '#f5f5f5',
            text: '#2C2C2C'
        },
        accent: {
            main: '#F8DC4D',
        },
        primary: {
            main: '#38056C',
        },
        background: {
            primary: '#38056C',
            default: '#f5f5f5',
        },
        text: {
            primary: "#f5f5f5",
            secondary: "#000",
            label: '#f5f5f5',
            input: 'rgba(0, 0, 0, 0.25)',
        }
    },
}

export default blueTheme