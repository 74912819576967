import { useMetadata } from '@/api/hooks/useMetadata'
import { ProjectTree } from '@/api/project'
import { TreeNodeData } from '@/components/Elements/tree'
import ArrowIcon from '@/components/icons/ArrowIcon'
import MoreIcon from '@/components/icons/MoreIcon'
import { layerChangeEvent } from '@/components/layout/AppBar/search/SearchItem'
import { useToast } from '@/components/shared/toast/useToast'
import useHover from '@/hooks/useHover'
import useResponsive from '@/hooks/useResponsive'
import { useGlobalStore } from '@/stores/globalStore'
import { RolesEnum, useUserStore } from '@/stores/userStore'
import React, { useMemo, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import TreeList, { TreeListStyles } from './TreeList'
import { useSettingsSelector } from '@/hooks/use-settings-selector'
import { floorIsApproach } from '@/utils/validation/floorIsApproach'

export const checkHidden = (tree: ProjectTree | TreeNodeData, meta) => {
	const type = tree.type_uid
	if (!type || typeof meta !== 'object') return false
	const typeMeta = meta?.layers?.[type]
	if (!typeMeta) return false
	const plugins = meta?.layers?.[type]?.plugin_data

	if (!plugins) return false
	const fields = Object.values(plugins).find((v) => (v as any)['fields'])?.[
		'fields'
	]

	if (!fields) return false
	const hideProp = fields.find((prop) => (prop as any).name === '#hide')

	if (!hideProp) return false
	const treePlugins = Object.values(tree.plugin_data).reduce(
		(acc, val) => (acc as any[]).concat(val),
		[] as any[],
	)

	const isHidden = (treePlugins as any).find(
		(v) => v.field_id == hideProp.id && v.value,
	)

	return isHidden ? isHidden['value'] : false
}

export const checkTreeHidden = (tree: ProjectTree | TreeNodeData, meta) => {
	const type = tree.type_uid
	if (!type || typeof meta !== 'object') return false
	const typeMeta = meta[type]
	if (!typeMeta) return false
	const plugins = meta[type]?.plugin_data

	if (!plugins) return false
	const fields = Object.values(plugins).find((v) => (v as any)['fields'])?.[
		'fields'
	]

	if (!fields) return false
	const hideProp = fields.find((prop) => (prop as any).name === '#hide')

	if (!hideProp) return false
	const treePlugins = Object.values(tree.plugin_data).reduce(
		(acc, val) => (acc as any[]).concat(val),
		[] as any[],
	)

	const isHidden = (treePlugins as any).find(
		(v) => v.field_id == hideProp.id && v.value,
	)

	return isHidden ? isHidden['value'] : false
}

const TreeItem: React.FC<{ tree: ProjectTree; depth?: number }> = ({
	tree,
	depth = 1,
}) => {
	const ref = useRef(null)
	const hover = useHover(ref)
	const { isMobile } = useResponsive()
	const { metadata } = useMetadata()
	const { enqueueToast } = useToast()

	// data selectors
	const activeLayer = useGlobalStore((state) => state.activeLayer)
	const setActiveLayer = useGlobalStore((state) => state.setActiveLayer)
	const setLayerModal = useGlobalStore((state) => state.setLayerModal)
	const setSelector = useGlobalStore((state) => state.setSelector)
	const role = useUserStore((state) => state.role)
	const depthLimit = useSettingsSelector((settings) => settings.depth, 2)
	console.log(depthLimit)

	const isHidden = useMemo(
		() => checkTreeHidden(tree, metadata?.layers),
		[tree, metadata?.layers],
	)
	const childs = useMemo(
		() =>
			tree.childs.filter(
				(item) =>
					(role === RolesEnum.Admin
						? true
						: !checkTreeHidden(item, metadata?.layers)) &&
					floorIsApproach(item.name),
			) || [],
		[tree, metadata?.layers, role],
	)

	const hasChildren = tree.childs && tree.childs.length && childs.length > 0
	const expandable = !!hasChildren
	const active = tree.id == activeLayer || hover

	// local state
	const [expanded, setExpanded] = useState<boolean>(
		expandable && depth < depthLimit,
	)

	const handleExpand = (e) => {
		e.stopPropagation()
		setExpanded(!expanded)
	}
	const handleAbout = (e) => {
		e.stopPropagation()
		setLayerModal(tree.id)
	}

	const handleLayerChange = () => {
		if (!tree.own_view) {
			enqueueToast(
				{
					title: 'Уровень не найден',
					message: 'У запрашиваемого уровня нет собственной подложки.',
				},
				{ variant: 'error' },
			)
			return
		}
		if (isMobile) {
			setSelector(null)
		}
		document.dispatchEvent(layerChangeEvent(tree.id))

		setActiveLayer(tree.id)
	}

	if (isHidden && role !== RolesEnum.Admin) return null

	return (
		<ListItem>
			<ListName ref={ref} onClick={handleLayerChange} $active={active}>
				<NameWrapper>{tree.name}</NameWrapper>
				<ItemControls>
					{active && (
						<AboutButton onClick={handleAbout}>
							<MoreIcon />
						</AboutButton>
					)}
					{expandable && (
						<ExpandButton
							onClick={expandable ? handleExpand : undefined}
							$expanded={expanded}
						>
							<ArrowIcon color="#f5f5f5" />
						</ExpandButton>
					)}
				</ItemControls>
			</ListName>
			<TreeList $expanded={expanded}>
				{childs.map((child) => (
					<TreeItem tree={child} key={child.id} depth={depth + 1} />
				))}
			</TreeList>
		</ListItem>
	)
}

export default TreeItem

const ItemControls = styled.div`
	display: flex;
	align-items: center;
`

const ExpandButton = styled.button<{ $expanded?: boolean }>`
	outline: none;
	border: none;
	background: transparent;
	transform: rotate(180deg);
	transition: transform 0.2s ease-in;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover {
		background: rgba(0, 0, 0, 0.3);
	}

	${({ $expanded }) =>
		$expanded &&
		css`
			transform: rotate(90deg);
		`}
`

const ListName = styled.div<{ $active?: boolean }>`
	/* padding: 8px; */
	min-height: 40px;
	border-radius: 8px;
	color: ${(props) => props.theme.palette.text.primary};
	position: relative;
	display: flex;
	justify-content: space-between;

	&:hover {
		color: #79ff3a;
		text-decoration: underline;
	}

	${({ $active }) =>
		$active &&
		css`
			color: #79ff3a;
			text-decoration: underline;
		`}
`

const ListItem = styled.li`
	${TreeListStyles} /* padding-top: 0.8rem; */ position: relative;
	cursor: pointer;
	min-height: 42px;
`

const AboutButton = styled.button`
	outline: none;
	border: none;
	border-radius: 50%;
	width: 24px;
	height: 24px;
	background: #79ff3a;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	position: absolute;
	/* top: 12px; */
	right: 42px;
`

const NameWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-right: 60px;
	user-select: none;
	max-width: 300px;
`
