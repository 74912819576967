const RUSSIAN_LETTERS_TRANSLATION = {
  "А": "A",
  "В": "B",
  "Е": "E",
  "К": "K",
  "М": "M",
  "Н": "H",
  "О": "O",
  "Р": "P",
  "С": "C",
  "Т": "T",
  "У": "Y",
  "Х": "X"
};

const AVAILABLE_DIGITS = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

/**
 * D используется в дипломатических номерах
 */
const AVAILABLE_LETTERS = ["A", "B", "E", "K", "M", "H", "O", "P", "C", "T", "Y", "X", "D"];

const AVAILABLE_SYMBOLS = AVAILABLE_DIGITS.concat(AVAILABLE_LETTERS);

const VEHICLE_FORMATS = [
  /^\D\d{3}\D{2}\d{2,3}$/,
  /^\D{2}\d{3}\d{2,3}$/,
  /^\D{2}\d{4}\d{2,3}$/,
  /^\D{2}\d{5}\d{2,3}$/,
  /^\D{2}\d{2}\D{2}\d{2,3}$/,
  /^\D{2}\d{3}\D{1}\d{2,3}$/,
  /^\D{3}\d{3}\d{2,3}$/,
  /^\d{3}\D{1,2}\d{1,3}\d{2,3}$/,
  /^\d{4}\D{2}\d{2,3}$/,
];

export const validateVehicleNumber = (value: string) => 
  VEHICLE_FORMATS.some((regexp) => regexp.test(value)) 
    ? "" 
    : "Некорректный номер"

export const extractVehicleNumber = (value: string) =>
  value
    .replace(/\s/g, '')
    .toUpperCase()
    .split("")
    .map(symbol => RUSSIAN_LETTERS_TRANSLATION[symbol] ?? symbol)
    .filter((symbol) => AVAILABLE_SYMBOLS.includes(symbol))
    .slice(0, 11)
    .join("")
    .match(/(\d{0,4})\d*(\D{0,3})\D*(\d{0,7})\d*(\D{0,2})\D*(\d{0,3})\d*/)
    ?.slice(1, 6)
    ?.join("") ?? ""