import { BookingItem } from '@/api/bookings';
import { useBookings } from '@/api/hooks/useBookings';
import { useExcludedLayerIds } from '@/api/hooks/useHiddenLayers';
import { useMetadata } from '@/api/hooks/useMetadata';
import { useUserCardFields } from '@/api/hooks/useUserCardFields';
import { RuntimeFieldData } from '@/api/user';
import Close from '@/components/Close';
import Modal from '@/components/Modal/Modal';
import Toolbar from '@/components/Toolbar/Toolbar';
import { useSettingsSelector } from '@/hooks/use-settings-selector';
import useResponsive from '@/hooks/useResponsive';
import useSorting from '@/hooks/useSorting';
import { translate } from '@/i18n';
import { useProjectStore } from '@/stores/projectStore';
import { Input } from '@/ui/components/Field/Input';
import { SelectInput } from '@/ui/components/Field/Select';
import Pagination from '@/ui/components/Pagination/Pagination';
import media from '@/ui/media';
import { printPDF } from '@/utils/func/print';
import { formatToReport } from '@/utils/helpers/dates.helpers';
import { addMinutes, endOfWeek, format, parseISO, startOfWeek, subMinutes } from 'date-fns';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { ErrorBoundary } from 'react-error-boundary';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { BookingDate, bookingTypeTranslate, extractSchedule } from '../../../Bookings';
import MobileBookingItem from '../../../MobileBookingItem';
import ReportDateSelector from '../../ReportDateSelector';
import { ReportButton } from '../../ReportView';

type ReportModalProps = {
    report: string | null
    item: {
        name: string
        key: string
    }
    onClose: () => void
}

const weekdays = {
    1: 'Пн.',
    2: 'Вт.',
    3: 'Ср.',
    4: 'Чт.',
    5: 'Пт.',
    6: 'Сб.',
    0: 'Вс.',
}

export const getRecurrentDates = (item) => {
    const { gap } = item

    const gapToSlots = Array.isArray(gap) ? gap.join(',') : gap
    const schedule =  extractSchedule(gapToSlots.split(','))
    const d = new Date()
    const offset = d.getTimezoneOffset()

    return `${`${format(parseISO(item.start + '.000Z'), 'dd.MM.yyyy HH:mm')} - ${format(parseISO(item.end + '.000Z'), 'dd.MM.yyyy HH:mm')}`}\r${schedule.map(slot => `${weekdays[new Date(slot.start).getDay()]} ${format(addMinutes(slot.start, -offset), 'HH:mm')} - ${format(addMinutes(slot.end, -offset), 'HH:mm')}`)}`
} 


export const fillUserWithUserData = (
	user?: any,
	fields?: RuntimeFieldData[],
) => {
	if (!user || !fields) return []

    const newUser = { ...user }

	fields.forEach((field) => {
        const data = newUser[field.uid]
        if (!data) return

        newUser[field.alias] = data
    })

    return newUser
}

const getBookingKey = (item) => `${item['id']}`
const useParkingData = ({ selection, name }) => {
    const { metadata } = useMetadata()
    const { sort, direction, handleSort } = useSorting()
    const parking = useSettingsSelector(state => state.parking)
    const parkingType = useMemo(() => metadata?.nodes?.find(node => node.name === parking)?.uid || '', [parking, metadata?.nodes])
	const hiddenLayers = useExcludedLayerIds()
    const { data, isLoading } = useBookings({
        page: 1,
        perPage: 20000,
        moment: 'current',
        start: subMinutes(selection.startDate, 1) || new Date(),
        end: selection.endDate || new Date(),
        name,
        placeType: parkingType,
        sort,
        direction
    })

    const reportItems = data?.items || []
    const { data: extra } = useUserCardFields()

    console.log(reportItems)

    const formatData = useMemo(() => {
        const customDataMap = new Map()

        reportItems?.forEach(item => {
            let customData = item['booking_custom_data']
            if (customData) {
                customDataMap.set(item.id, customData)
            }
        })

        return reportItems?.map(item => {
            const itemData = fillUserWithUserData(item, extra?.fields)
            const bookingType = item.rec != '0' ? 'recurrent' : item.end ? 'common' : 'constant'
            const date = bookingType !== 'recurrent' ? `${format(parseISO(item.start + '.000Z'), 'dd.MM.yyyy HH:mm')} ${bookingType !== 'constant' ?  `- ${format(parseISO(item.end + '.000Z'), 'dd.MM.yyyy HH:mm')}` : ''}` : getRecurrentDates(item)
            // const date = `${format(parseISO(item.start + '.000Z'), 'dd.MM.yyyy HH:mm')} ${bookingType !== 'constant' ?  `- ${format(parseISO(item.end + '.000Z'), 'dd.MM.yyyy HH:mm')}` : ''}`
            // const user = makeUserCardData(item as any, extra?.fields)

            if (hiddenLayers.includes(Number(item.parent_layer_id))) return null

            let customData = item['booking_custom_data'] || customDataMap.get(item['id']) || customDataMap.get(item['master_booking'])
            return {
                ...itemData,
                date,
                custom_data: customData,
                bookingType: bookingTypeTranslate[bookingType]
            }
        }).filter(v => v) || []
    }, [reportItems, hiddenLayers, extra?.fields])

    return { isLoading, data: isLoading ? null : formatData }
}

const ParkingReportModal: React.FC<ReportModalProps> = ({ report, item, onClose }) => {
    const [currentPage, setCurrentPage] = useState<number>(1)
    const { metadata } = useMetadata()

    const parking = useSettingsSelector(state => state.parking)
    const parkingType = useMemo(() => metadata?.nodes?.find(node => node.name === parking)?.uid || '', [parking, metadata?.nodes])
    const nodes = useProjectStore(state => state.nodes)
    const [parent, setParent] = useState<string>('')
    const [type, setType] = useState<string>('')
    const [toggle, setOpen] = useState<boolean>(false)
    const [selection, setSelection] = useState<{
        startDate: any
        endDate: any
        key: string
      }>({
        startDate: startOfWeek(new Date()),
        endDate: endOfWeek(addMinutes(new Date(), -30)),
        key: 'selection'
      })
    const [name, setName] = useState<string>('')
    const intl = useIntl()

      const { isLoading, data } = useParkingData({ selection, name })

    const { isDesktop } = useResponsive()

    const handleNameChange = (e) => setName(e?.target?.value)
    const handleParentChange = (e) => setParent(e?.target?.value)

    const debouncedNameResponse = useMemo(() => {
        return debounce(handleNameChange, 500)
    }, [])

    useEffect(() => {
        return () => debouncedNameResponse.cancel()
    }, [])

    const headers = [
        { label: 'ФИО', key: 'user', alias: 'report-full-name' },
        { label: 'Место', key: 'name', alias: 'report-object' },
        { label: 'Тип', key: 'bookingType', alias: 'report-booking-type' },
        { label: 'Номер автомобиля', key: 'custom_data', alias: 'report-custom-data' },
        { label: 'Табельный номер', key: 'personnel_number', alias: 'personnel-number' },
        { label: 'Дата', key: 'date', alias: 'report-date' },
    ].map(item => ({ ...item, label: intl.formatMessage({ id: item.alias }) }))


    const pdfCols = useMemo(() => headers.map(col => ({ header: col.label, dataKey: col.key })), [headers])


    return (
        <Modal maxWidth={1100} isOpen={!!report} onClose={onClose}>
            <Header>
                <Title>{translate('report-template', { name: intl.formatMessage({ id: item.key }) })}</Title>
                <ExportWrapper>
                    <ReportButton
                        onClick={() => printPDF({
                            name: report,
                            columns: pdfCols,
                            body: data
                        })}
                    >
                        PDF
                    </ReportButton>
                    <CSVLink
                        data={data}
                        headers={headers}
                        separator={";"}
                        filename={report + ` ${formatToReport(new Date())}`}
                    >
                        <ReportButton>CSV</ReportButton>
                    </CSVLink>
                    <Close color="#000" onClick={onClose} />
                </ExportWrapper>
            </Header>

            <Toolbar>
                <Toolbar.Item xs={6} md={4}>
                    <Toolbar.BlackLabel>{translate('date')}</Toolbar.BlackLabel>
                    <Input
                        $fullWidth
                        value={`${format(
                        selection.startDate,
                        'dd.MM.yyyy HH:mm'
                        )} - ${format(selection.endDate, 'dd.MM.yyyy HH:mm')}`}
                        onClick={() => setOpen(true)}
                    />
                    <ReportDateSelector
                        open={toggle}
                        setOpen={setOpen}
                        selection={selection}
                        setSelection={setSelection}
                    />
                </Toolbar.Item>
                <Toolbar.Item xs={6} md={4}>
                    <Toolbar.BlackLabel>{translate('position')}</Toolbar.BlackLabel>
                    <SelectInput $fullWidth defaultValue={parent} onChange={handleParentChange}>
                        <option value="">{translate('all-levels')}</option>
                        {nodes.map(node => <option key={node.id} value={node.id}>{node.name}</option>)}
                    </SelectInput>
                </Toolbar.Item>
            </Toolbar>

            {parking ? (
                parkingType ? (
                    <Table>
                        {isDesktop && (
                            <TableHead>
                                <tr>
                                    {headers.map(header => (
                                        <TableHeadCell key={header.key}>
                                            {header.label}
                                        </TableHeadCell>
                                    ))}
                                </tr>
                            </TableHead>
                        )}

                        <TableBody>
                            {isLoading ? (
                                <tr>
                                    <td colSpan={6}>
                                        <NotFound>{translate('loading')}</NotFound>
                                    </td>
                                </tr>
                            ) : data ? data.slice((currentPage - 1) * 20, currentPage * 20).map(item => <ElementItem key={item.id} item={item} />) : (
                                <tr>
                                    <td colSpan={6}>
                                        <NotFound>{translate('no-results')}</NotFound>
                                    </td>
                                </tr>
                            )}
                        </TableBody>
                    </Table>
                ) : <div>Некорректно задан тип "Парковочные места"</div>
            ) : (
                <div> Укажите название типа "Парковочные места"</div>
            )}

            <Pagination inverse currentPage={currentPage} total={data?.total || 0} handlePageChange={setCurrentPage} />
        </Modal>
    )
}

export default ParkingReportModal

const TableHeadCell = styled.th`
    text-align: left;
`

const TableBody = styled.tbody``

const TableHead = styled.thead`
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
`

const Table = styled.table`
    margin: 1rem 0;
    overflow-y: auto;
    height: 100%;
    width: 100%;
    overflow-x: hidden;


    tr td:last-child {
        width: 1%;
        white-space: nowrap;
    }

    td:not(:last-child) {
        padding: 0 8px 0 0;
    }

    tr, td {
        padding-top: 6px;
        padding-bottom: 6px;
    }
`

const TableCell = styled.td`
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
`

const ElementItem: React.FC<{ item: BookingItem }> = ({ item }) => {
    const bookingType = item.rec != '0' ? 'recurrent' : item.end ? 'common' : 'constant'
    const { isDesktop } = useResponsive()

    if (!isDesktop) return (
        <MobileBookingItem
            fio={item.user}
            name={item.name}
            type={bookingTypeTranslate[bookingType]}
            num={(item['custom_data'] || '').toLowerCase()}
            date={<BookingDate type={bookingType} gap={item.gap} date={{ start: item.start, end: item.end }} />}
        />
    )

    return (
        <tr>
            <TableCell>{item.user}</TableCell>
            <TableCell>{item.name}</TableCell>
            <TableCell>{bookingTypeTranslate[bookingType]}</TableCell>
            <TableCell>{(item['custom_data'] || '').toLowerCase()}</TableCell>
            <TableCell>{(item['personnel_number'] || '')}</TableCell>
            <TableCell>
                <ErrorBoundary
                    fallback={<div>Ошибка отображения даты</div>}
                >
                    <BookingDate type={bookingType} gap={item.gap} inverse={true} date={{ start: item.start, end: item.end }} />
                </ErrorBoundary>
            </TableCell>
        </tr>
    )
}


const NotFound = styled.div`
  width: 100%;
  text-align: center;
  padding: 12px 0;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.lg`
      flex-direction: column;
      align-items: flex-start;
  `}
`

const ExportWrapper = styled.div`
  display: flex;
  align-items: center;

  * {
      &:not(:last-child) {
          margin-right: 0.8rem;
      }
  }

  
  ${media.lg`
      margin-top: 20px;
  `}
`

const Title = styled.div`
font-weight: 500;
font-size: 2.4rem;
line-height: 2.4rem;
color: #000000;
`