import { useTree } from "@/api/hooks/useTree";
import { useGlobalStore } from "@/stores/globalStore";
import { useMemo } from "react";
import styled from "styled-components";
import { layerChangeEvent } from "../layout/AppBar/search/SearchItemV2";
import BackIcon from './../icons/BackIcon';

export const BackButton = () => {
    const { layers } = useTree()
    const { activeLayer, setActiveLayer } = useGlobalStore()
    const { nodes } = layers || {}
    const current = useMemo(
      () => nodes?.find((node) => node.id == activeLayer),
      [nodes, activeLayer]
    )
  
    const handleLayerChange = () => {
      if (!current || !current.parent) return
      document.dispatchEvent(layerChangeEvent(current.parent))
  
      setActiveLayer(Number(current.parent))
    }
  
    if (!current || !current.parent) return <div />
  
    return (
      <Item onClick={handleLayerChange}>
        <BackIcon />
      </Item>
    )
  }

  const Item = styled.div`
  //width: 100%;
  height: 48px;
  width: 48px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  //padding: 2rem 1rem;
  position: relative;
  transition: background 0.3s, color 0.3s;

  p {
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    margin-top: 0.8rem;
  }

  .hover-icon,
  .hover-color,
  .hover-alt,
  .hover-fill {
    transition: all 0.3s;
  }

  img {
    z-index: 2;
    user-select: none;
  }

  background: ${(props) => props.theme.palette.background.primary};
  color: ${(props) => props.theme.palette.text.primary};

  .hover-icon {
    fill: transparent;
  }

  .hover-fill {
    fill: ${(props) => props.theme.palette.text.primary};
  }

  .hover-color {
    fill: ${(props) => props.theme.palette.text.primary};
    stroke: ${(props) => props.theme.palette.text.primary};
  }

  .hover-alt {
    fill: ${(props) => props.theme.palette.text.primary};
    stroke: ${(props) => props.theme.palette.background.primary};
  }
`