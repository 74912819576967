type MaintenanceOptionType = {
	type: string
	recipient: string
}
type MaintenanceSectionType = {
	types: string[]
	options: MaintenanceOptionType[]
}

type GetMaintenanceOptionsProps = {
	options: MaintenanceSectionType[]
	type?: string
}

type MaintenanceOption = {
	label: string
	value: string
}

function getTypeMaintenanceOptions(
	options: MaintenanceSectionType[],
	type = 'default',
): MaintenanceOptionType[] {
	const normalizedType = type.toLowerCase()

	const selectedOption = options.find((option) =>
		option.types.map((opt) => opt.toLowerCase()).includes(normalizedType),
	)
	if (selectedOption) return selectedOption.options

	const defaultOption = options.find((option) =>
		option.types.map((opt) => opt.toLowerCase()).includes('default'),
	)
	if (defaultOption) return defaultOption.options

	return []
}

export const getMaintenanceOptions = ({
	options,
	type,
}: GetMaintenanceOptionsProps): MaintenanceOption[] => {
	const maintenanceOptions = getTypeMaintenanceOptions(options, type)
	return maintenanceOptions.map((data, idx) => ({
		label: data.type,
		value: idx + ':dl:' + data.recipient,
	}))
}
