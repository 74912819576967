import React from 'react'
import styled, { css } from 'styled-components'
import { media } from '@/ui/media';

export interface MenuItemProps {
    name: string
    icon: React.ElementType
    isActive?: boolean
    onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const MenuItem: React.FC<MenuItemProps> = ({ name, icon: Icon, isActive, onClick }) => {
    return (
        <Item $active={isActive} onClick={onClick}>
            <Icon />
            <p>{name}</p>
        </Item>
    )
}

export default MenuItem

const Item = styled.div<{ $active?: boolean }>`
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    padding: 2rem 1rem;
    position: relative;
    transition: background 0.3s, color 0.3s;
    color: ${props => props.theme.palette.text.secondary};

    p {
        font-weight: 400;
        font-size: 10px;
        line-height: 16px;
        text-align: center;
        margin-top: 0.8rem;
    }

    .hover-office, .hover-icon, .hover-color, .hover-alt, .hover-fill {
        transition: all 0.3s;
    }
    .hover-icon {
        fill: transparent;
    }
    &:hover {
        background: ${props => props.theme.palette.background.primary};
        color: #79FF3A;

        .hover-icon {
            fill: transparent;
        }

        .hover-office {
            stroke: #79FF3A;
        }

        .hover-fill {
            fill: #79FF3A;
            stroke: #79FF3A;
        }

        .hover-color {
            fill: #79FF3A;
            stroke: #79FF3A;
        }

        .hover-alt {
            stroke: ${props => props.theme.palette.background.primary};
            fill: #79FF3A;
        }
    }

    img {
        z-index: 2;
        user-select: none;
    }

    ${({ $active }) => $active && css`
        background: ${props => props.theme.palette.background.primary};
        color: #79FF3A;
        
        .hover-icon {
            fill: transparent;
        }

        .hover-office {
            stroke: #79FF3A;
        }

        .hover-fill {
            fill: #79FF3A;
            stroke: #79FF3A;
        }

        .hover-color {
            fill: #79FF3A;
            stroke: #79FF3A;
        }

        .hover-alt {
            stroke: ${props => props.theme.palette.background.primary};
            fill: #79FF3A;
        }
    `}

    ${media.lg`
        height: 75px;

        p {
            display: none;
        }
    `}
`