import React from 'react'
import BaseLayout from '../components/layout/BaseLayout'
import styled from 'styled-components';
import whiteLogo from '../images/themes/blue/logo.png'
import greyLogo from '../images/themes/white/logo.png'
import useRouteQuery from '../hooks/useRouteQuery';
import LoginForm from './../components/LoginForm';
import { FormattedMessage } from 'react-intl'
import { translate } from '@/i18n';

const LoginContainer: React.FC = (props) => {

    const query = useRouteQuery()

    return (
        <BaseLayout title="Авторизация">
            <Wrapper>
                <Container>
                    <Brand>
                        <BrandImage>
                            <svg
                                width="50"
                                height="50"
                                viewBox="0 0 50 50"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect y="47" width="37" height="3" fill="currentColor" />
                                <rect width="50" height="3" fill="currentColor" />
                                <rect x="3" y="20" width="4" height="3" fill="currentColor" />
                                <rect x="31" y="28" width="16" height="3" fill="currentColor" />
                                <rect x="≤15" y="28" width="10" height="3" fill="currentColor" />
                                <rect x="3" y="28" width="4" height="3" fill="currentColor" />
                                <rect x="15" y="20" width="26" height="3" fill="currentColor" />
                                <rect x="44" y="47" width="6" height="3" fill="currentColor" />
                                <rect x="28" y="1" width="3" height="22" fill="currentColor" />
                                <rect x="19" y="28" width="3" height="22" fill="currentColor" />
                                <rect x="31" y="28" width="3" height="13" fill="currentColor" />
                                <rect x="47" width="3" height="50" fill="currentColor" />
                                <rect width="3" height="50" fill="currentColor" />
                            </svg>
                        </BrandImage>
                        <BrandName>
                            {translate("brand-name")}
                        </BrandName>
                    </Brand>

                    <Content>
                        <AuthContainer>
                            <Title>
                                {translate("auth_auth-text")}
                            </Title>

                            <div style={{ margin: "1.6rem 0" }}>
                                <LoginForm workspaceId={query.get("workspace_id")} projectId={query.get("project_id")} />
                            </div>
                        </AuthContainer>
                    </Content>
                </Container>
            </Wrapper>
        </BaseLayout>
    )
}

export default LoginContainer

const AuthContainer = styled.div`
    max-width: 300px;
    width: 100%;
`
const Title = styled.h1`
    font-weight: 500;
    font-size: 24px;
    line-height: 16px;
    color: ${props => props.theme.palette.text.primary};
`

const Content = styled.div`
    display: flex;
    flex-grow: 1;
    align-items: center;
    padding: 20px 0;
`

const Wrapper = styled.div`
    background: ${props => props.theme.palette.background.primary};
    width: 100%;
    padding: 10px;
    display: flex;
`
const Container = styled.div`
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`
const BrandImage = styled.div`
    margin-right: 16px;
    color: #79FF3A;
`
const Brand = styled.div`
    display: flex;
    align-items: center;
`
const BrandName = styled.h2`
    font-weight: 500;
    font-size: 24px;
    line-height: 25px;
    text-transform: uppercase;
    color: #79FF3A;
`