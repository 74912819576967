import React, { FC, PropsWithChildren, useRef } from 'react'
import styled, { createGlobalStyle, css } from 'styled-components'
import { Portal } from 'react-portal'
import Close from '../Close'
import { animated, useTransition } from 'react-spring'
import { easePoly } from 'd3-ease'
import { RolesEnum, useUserStore } from '@/stores/userStore'
import { media } from '@/ui/media'

type ModalProps = {
	isOpen: boolean
	onClose: () => void
	close?: boolean
	maxWidth?: number
}

export const AdminModal: FC<PropsWithChildren<ModalProps>> = ({
	children,
	...otherProps
}) => {
	const { role } = useUserStore()

	if (role !== RolesEnum.Admin) return null

	return <Modal {...otherProps}>{children}</Modal>
}

const Modal: FC<PropsWithChildren<ModalProps>> = ({
	isOpen,
	onClose,
	children,
	maxWidth,
	close = false,
}) => {
	const ref = useRef<any>(null)

	// useOnClickOutside(ref, onClose)

	const handleClose = (e) => {
		if (!ref.current || ref.current.contains(e.target)) {
			return
		}

		onClose()
	}

	const backdropTransitions = useTransition(isOpen, {
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
		config: {
			duration: 100,
			easing: easePoly.exponent(2),
		},
	})

	const modalTransitions = useTransition(isOpen, {
		from: { opacity: 0, scale: 0.9 },
		enter: { opacity: 1, scale: 1 },
		leave: { opacity: 0, scale: 0.9 },
		config: {
			duration: 300,
			easing: easePoly.exponent(2),
		},
	})

	return (
		<Portal node={document && document.getElementById('modals')}>
			{backdropTransitions((styles, item) =>
				item ? (
					<Backdrop
						style={styles}
						// onClick={handleClose}
					>
						{modalTransitions((modalStyles, modal) =>
							modal ? (
								<Content ref={ref} style={modalStyles} $maxWidth={maxWidth}>
									{close && <ModalClose color="#000" onClick={onClose} />}
									{children}
								</Content>
							) : (
								''
							),
						)}
					</Backdrop>
				) : (
					''
				),
			)}
			<ScrollDisabler />
		</Portal>
	)
}

export default Modal

const Title = styled.div`
	color: #000000;
	font-weight: 600;
	font-size: 24px;
	line-height: 24px;
`
const Body = styled.div`
	overflow-y: auto;
	overflow-x: hidden;
	max-height: 80vh;
`
// @ts-ignore
Modal.Title = Title
// @ts-ignore
Modal.Body = Body

const ScrollDisabler = createGlobalStyle`
    body {
        overflow-y: hidden;
    }
`

const ModalClose = styled(Close)`
	position: absolute;
	top: 16px;
	right: 16px;
`

const Backdrop = styled(animated.div)`
	width: 100%;
	background-color: rgba(0, 0, 0, 0.5);

	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 10001;

	display: flex;
	justify-content: center;
	align-items: center;
`

const Content = styled(animated.div)<{ $maxWidth?: number }>`
	background: #f5f5f5;
	max-width: 1035px;
	width: 100%;
	max-height: 100%;
	max-height: var(--app-height);
	padding: 30px 40px;
	position: relative;
	overflow-y: auto;
	overflow-x: hidden;
	z-index: 20001;
	border-radius: 8px;

	${({ $maxWidth }) =>
		$maxWidth &&
		css`
			max-width: ${$maxWidth}px;
		`}

	${media.lg`
    padding: 20px;
    /* position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 0px; */
  `}
    ${media.md`
    padding: 10px;
  `}
`
