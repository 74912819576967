import { translate } from '@/i18n'
import { RolesEnum, useUserStore } from '@/stores/userStore'
import { FormLabel } from '@/ui/components/Form/FormLabel'
import Grid from '@/ui/components/Grid'
import { Field, useFormikContext } from 'formik'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { Portal } from 'react-portal'
import { ModalSelectInputField } from '../shared/ModalSelectInputField'
import SelectUserModal from './SelectUserModal'

const SelectUserField = () => {
    const [isOpen, setOpen] = useState(false)
    const { setFieldValue } = useFormikContext()
    const intl = useIntl()

    const role = useUserStore(state => state.role)

    const handleEmployeeSelection = () => {
        if (role == RolesEnum.Admin) {
            setOpen(true)
        }
    }

    const isDisabled = role !== RolesEnum.Admin

    return (
        <>
            <Grid item xs={12} md={4} onClick={handleEmployeeSelection}>
                <FormLabel>{translate('choose-employee')}</FormLabel>
                <Field
                    name="user"
                    $fullWidth
                    type="text"
                    placeholder={intl.formatMessage({ id: 'employee' })}
                    component={ModalSelectInputField}
                    // disabled
                    style={{ opacity: isDisabled ? 0.5 : 1, pointerEvents: 'none' }}
                />
            </Grid>
            <Portal>
                <SelectUserModal isOpen={isOpen} setFieldValue={setFieldValue} onClose={() => setOpen(false)} />
            </Portal>
        </>
    )
}

export default SelectUserField