import { DefaultButton } from '@/components/ui/button/DefaultButton'
import { PrimaryButton } from '@/components/ui/button/PrimaryButton'
import { Field, Formik } from 'formik'
import styled from 'styled-components'
import Grid from '@/ui/components/Grid'
import { FormLabel } from '@/ui/components/Form/FormLabel'
import { SelectField } from '@/ui/components/Field/Select'
import { FormControls } from '@/components/ui/form/FormControls'
import { InputField } from '@/ui/components/Field/Input'
import { useIntl } from 'react-intl'
import { translate } from '@/i18n'
import { useMutation } from 'react-query'
import { MailService } from '@/api/services/mail.service'
import { useToast } from '../shared/toast/useToast'
import { useMaintenanceDialogStore } from '@/components/Maintenance/MaintenanceModal'
import { useMaintenance } from './lib/use-maintenance'
import { createMailParams } from '@/components/Maintenance/lib/create-mail-params'

const MaintenanceForm = () => {
	const intl = useIntl()
	const { enqueueToast } = useToast()
	const { type, seat, path, employee, onClose } = useMaintenanceDialogStore(
		(state) => ({
			type: state.type,
			path: state.path,
			seat: state.seat,
			employee: state.employee,
			onClose: state.onClose,
		}),
	)
	const { user, userEmail, options } = useMaintenance({ type, seat })

	const handleFormSubmit = async (values, { setSubmitting }) => {
		setSubmitting(true)
		const reason = options.find((opt) => opt.value == values.type)?.label

		if (values.more.trim().length < 3) {
			enqueueToast('Комментарий должен содержать более 3 символов', {
				variant: 'error',
			})
			setSubmitting(false)
			return
		}

		const mailParams = createMailParams({
			reason: reason || 'Обслуживание',
			path: path || [],
			messageParams: {
				seat: values.seat,
				created: user?.display || '',
				reason: reason || '',
				message: values.more,
			},
			recipient: userEmail,
		})

		mutate(mailParams)
		setSubmitting(false)
	}

	const { mutate } = useMutation(
		'maintenance-user',
		(data: ReturnType<typeof createMailParams>) => MailService.sendMail(data),
		{
			onSuccess() {
				onClose()
				enqueueToast('Отправлено', { variant: 'success' })
			},
			onError(data: any) {
				enqueueToast('Не удалось отправить', { variant: 'error' })
			},
		},
	)

	return (
		<Formik
			onSubmit={handleFormSubmit}
			enableReinitialize
			initialValues={{
				seat,
				employee,
				type: options?.at(0)?.value,
			}}
		>
			{({ handleSubmit, isSubmitting }) => (
				<form onSubmit={handleSubmit}>
					<FormContainer>
						<Grid container>
							<Grid item xs={12} md={12}>
								<FormLabel>{translate('place')}</FormLabel>
								<Field
									name="seat"
									$fullWidth
									required={true}
									placeholder={intl.formatMessage({ id: 'place' })}
									component={InputField}
									disabled={true}
									style={{ opacity: 0.5 }}
								/>
							</Grid>
							<Grid item xs={12} md={12}>
								<FormLabel>{translate('employee')}</FormLabel>
								<Field
									name="employee"
									$fullWidth
									required={true}
									placeholder={intl.formatMessage({ id: 'employee' })}
									component={InputField}
									disabled={true}
									style={{ opacity: 0.5 }}
								/>
							</Grid>
							<Grid item xs={12} md={12}>
								<FormLabel>{translate('service-type')}</FormLabel>
								<Field
									name="type"
									$fullWidth
									required={true}
									component={SelectField}
									options={options}
								/>
							</Grid>
							<Grid item xs={12} md={12}>
								<FormLabel>{translate('more-info')}</FormLabel>
								<Field
									name="more"
									$fullWidth
									placeholder={intl.formatMessage({ id: 'describe' })}
									component={InputField}
									textarea
									required={true}
									rows={10}
								/>
							</Grid>
						</Grid>
					</FormContainer>

					<ControlsWrapper>
						<FormControls>
							<PrimaryButton $fullWidth type="submit" disabled={isSubmitting}>
								{translate('send')}
							</PrimaryButton>
							<DefaultButton $fullWidth type="button" onClick={onClose}>
								{translate('cancel')}
							</DefaultButton>
						</FormControls>
					</ControlsWrapper>
				</form>
			)}
		</Formik>
	)
}

const FormContainer = styled.div`
	padding: 1rem 0;
	max-height: 100%;
`

export default MaintenanceForm

const ControlsWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
`
