import React from 'react'
import styled from 'styled-components'
import CloseIcon from './icons/CloseIcon'

const CloseWrapper = styled.button`
    outline: none;
    border: none;
    cursor: pointer;
    background: transparent;
    transition: opacity 0.3s;
    z-index: 2001;
    color: inherit;

    &:hover {
        opacity: 0.7;
    }
`

const Close = ({ color = 'white', onClick = () => {}, ...otherProps }) => {
    return (
        <CloseWrapper onClick={onClick} {...otherProps}>
            <CloseIcon color={color} />
        </CloseWrapper>
    )
}

export default Close