import { isEqual } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import useSettings from "@/hooks/use-settings";

export function useProjectMulti() {
	const params = useParams<{ workspaceId: string; projectId: string }>()

	const [paramsState, setParamsState] = useState(params)

	useEffect(() => {
		if (!isEqual(paramsState, params)) {
			setParamsState(params)
		}
	}, [params])

	return useMemo(
		() => ({
			workspaceId: Number(paramsState.workspaceId),
			projectId: Number(paramsState.projectId),
		}),
		[paramsState],
	)
}

function useProjectSingle() {
	const { data } = useSettings()
	const { workspaceId, projectId } = data

	return {
		projectId: Number(projectId),
		workspaceId: Number(workspaceId),
	}
}

export const useProject = process.env.SINGLE_PROJECT == 'true' ? useProjectSingle : useProjectMulti
